define("discourse/plugins/paczki-duplicate-notification-terminator/discourse/connectors/after-topic-footer-buttons/wipe-admin-notifications-button", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      wipeNotifications(topicId) {
        if (this.get("loading")) return;
        this.set("loading", true);
        (0, _ajax.ajax)(`/t/${topicId}/wipe-admin-notifications`, {
          type: "POST"
        }).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
      }
    }
  };
});